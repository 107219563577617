import { useState, useEffect } from 'react';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import socketClient from 'socket.io-client';
import classNames from 'classnames';
import moment from 'moment';
import logoWithText from '../../assets/images/logo-with-text.png';
import downCaret from '../../assets/images/down-caret.png';
import cog from '../../assets/images/cog.png';
import door from '../../assets/images/door.png';
import menu from '../../assets/images/menu.png';
import close from '../../assets/images/close.png';
import bell from '../../assets/images/bell.png';
import styles from './LoggedInNav.module.css';
import findArrayIndex from '../../utils/findArrayIndex.js';
import { getAllNotificationsApi } from '../../api/notifications.js';
import Notifications from './Notifications.jsx';

const io = socketClient(process.env.REACT_APP_BACK_END_DOMAIN, {
	extraHeaders: {
		token: localStorage.getItem('api-token')
	}
});

const LoggedInNav = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { user } = useOutletContext();
	const [showNotifications, setShowNotifications] = useState(false);
	const [showSubNav, setShowSubNav] = useState(false);
	const [showMobileLinks, setShowMobileLinks] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [unseenNotificationsCount, setUnseenNotificationsCount] = useState(0);

	const addNotification = (notification) => {
		const updatedNotificationObject = {...notification, created_at: moment().toISOString()};
		setNotifications((previous) => {
			const index = findArrayIndex(previous, 'id', updatedNotificationObject.id);
			return index === false ? [...previous, updatedNotificationObject] : previous;
		});
	};

	const getNotifications = async() => {
		const response = await getAllNotificationsApi();
		let body;
		try{
			body = await response.json();
		}
		catch(e){}
		if(response && response.ok){
			setNotifications(body.data.notifications);
		}
	};

	const getUnseenNotificationsCount = () => {
		let count = 0;
		for(let i = 0; i < notifications.length; i++){
			if(notifications[i].seen === false){
				count = count + 1;
			}
		}
		return count;
	};

	const toggleNotifications = () => {
		if(showSubNav){
			toggleSubNav();
		}
		setShowNotifications(!showNotifications);
	};

	const toggleSubNav = () => {
		if(showNotifications){
			toggleNotifications();
		}
		setShowSubNav(!showSubNav);
	};

	const toggleMobileLinks = () => {
		setShowMobileLinks(!showMobileLinks);
	};

	const logout = () => {
		localStorage.removeItem('api-token');
		navigate('/');
	};

	useEffect(() => {
        if(user.id){
            io.on('user/' + user.id, (data) => {
                addNotification(data);
            });
        }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.id]);

	useEffect(() => {
		if(!showNotifications){
			getNotifications();
		}
	}, [showNotifications]);

	useEffect(() => {
		setUnseenNotificationsCount(getUnseenNotificationsCount());
	}, [notifications]);

	return (
		<div className={styles.container}>
			<div className={styles.nav}>
				<a className={styles.title} href="/fundraisers">
					<img src={logoWithText} alt="Logo With Text"/>
				</a>
				<img className={classNames({[styles.menu]: !showMobileLinks, [styles.close]: showMobileLinks})} src={showMobileLinks ? close : menu} alt="Toggle Mobile Links" onClick={toggleMobileLinks}/>
				<div className={styles.links}>
					<a className={classNames(styles.link, {[styles.active]: pathname === '/dashboard'})} href="/dashboard">
						Dashboard
					</a>
					<a className={classNames(styles.link, {[styles.active]: pathname === '/fundraiser'})} href="/fundraiser">
						New Fundraiser
					</a>
				</div>
				<div className={styles.profileContainer}>
					<div className={styles.notificationsToggle} onClick={toggleNotifications}>
						<img className={styles.bell} src={bell} alt="Bell"/>
						{
							unseenNotificationsCount > 0 && (
								<span className={classNames(styles.unseenNotificationsCount, {[styles.extendedCount]: unseenNotificationsCount > 9})}>
									{unseenNotificationsCount < 10 ? unseenNotificationsCount : '9+'}
								</span>
							)
						}
					</div>
					{
						showNotifications && (
							<Notifications style={{zIndex: 300}} notifications={notifications}/>
						)
					}
					<div className={styles.profile} onClick={toggleSubNav}>
						{
							user.profile_picture_url && (
								<img className={styles.profilePicture} src={process.env.REACT_APP_CLOUDFRONT_S3_DOMAIN + user.profile_picture_url} alt="Profile"/>
							)
						}
						{
							!user.profile_picture_url && (
								<span className={styles.profilePicturePlaceholder}>
									{user.first_name?.slice(0, 1).toUpperCase()} {user.last_name?.slice(0, 1).toUpperCase()}
								</span>
							)
						}
						<span className={styles.name}>
							{user.first_name} {user.last_name}
						</span>
						<img className={styles.downCaret} src={downCaret} alt="Down Caret"/>
					</div>
					{
						showSubNav && (
							<div className={styles.subNav}>
								<a className={styles.subNavLink} href="/profile">
									<img className={styles.subNavIcon} src={cog} alt="Profile"/>
									Account settings
								</a>
								<button className={styles.subNavLink} onClick={logout}>
									<img className={styles.subNavIcon} src={door} alt="Logout"/>
									Logout
								</button>
							</div>
						)
					}
				</div>
			</div>
            {
                showMobileLinks && (
                    <div className={styles.mobileLinks}>
                        <a className={classNames(styles.link, {[styles.active]: pathname === '/dashboard'})}
                           href="/dashboard">
                            Dashboard
                        </a>
                        <a className={classNames(styles.link, {[styles.active]: pathname === '/fundraiser'})}
                           href="/fundraiser">
                            New Fundraiser
                        </a>
                        <a className={classNames(styles.link, {[styles.active]: pathname === '/fundraisers'})}
                           href="/fundraisers">
                            Public Fundraisers
                        </a>
                    </div>
                )
            }
        </div>
    )
};

export default LoggedInNav;